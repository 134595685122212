.contentDoubleDiv{
  position: absolute;
  left: 50%;
  
  width: 60%;
}

.thirdDoubleDiv{
  
  border: 2px solid var(--color-blue-dark) !important;
  border-radius: 30px !important;
  z-index: 0 !important;
  position: relative !important;
  width: 100%;
  left: 10px !important;
}

.secondDoubleDiv{
  border: 2px solid var(--color-blue-light) !important;
  border-radius: 30px !important;
  z-index: 1 !important;
  top: 15px !important;
  position: relative;
  width: 100%;
  left: -15px !important
}

.firstDoubleDiv{
  border: 2px solid var(--color-blue) !important;
  border-radius: 30px !important;
  z-index: 2 !important;
  top: -7px !important;
  left: 5px !important;
  position: relative;
  background-color: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}