.mainForgotPassword {
  background-image: url("../../img/textura-forgot.svg");

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
}


.contentForgotPassword{
  text-align: center;
  margin-top: 40px;
}

.logoForgotPassword {
  width: 300px;
  padding-bottom: 10px;
}

.closeForgotPassword {
  text-align: right;
  margin: 10px !important;
  cursor: pointer;
}

.titleForgotPassword{
  padding-top: 10px !important;
  border-top-width: 2px !important;
  border-top-color: var(--color-blue) !important;
  border-top-style: solid;
  border-bottom-width: 2px !important;
  border-bottom-color: var(--color-blue) !important;
  border-bottom-style: solid;
  display: inline-block;
}