:root {
    --fonte-familia-corpo: 'Inter', sans-serif;
    --fonte-familia-logo: 'Inter', cursive;
    --fonte-familia-titulo-pagina: 'Inter', cursive;

    --fonte-peso-input: 300;
    --fonte-peso-cartao: 500;
    --fonte-peso-categorias: 500;
    --fonte-peso-menu-cabecalho-item: 500;

    --fonte-tamanho-logo: 1.75rem;
    --fonte-tamanho-cartao-titulo: 1.75rem;
    --fonte-tamanho-cartao-post-titulo: 1.375rem;
    --fonte-tamanho-input-label: 1.25rem;
    --fonte-tamanho-botao: 1.375rem;
    --fonte-tamanho-formulario-fieldset: 1.375rem;
    --fonte-tamanho-tabela-cabecalho: 1.375rem;
    --fonte-tamanho-titulo-pagina: 1.75rem;
    --fonte-tamanho-menu-cabecalho-item: 1.2rem;
    --fonte-tamanho-naoencontrado-texto: 1.25rem;

    --color-red: #F34213;
    --color-blue: #05C7F2;
    --color-black: #262224;
    --color-white: #ffffff;
    --color-orange: #F27D16;
    --color-orange-light: #F2913D;
    --color-orange-mid: #FF7801;
    --color-blue-dark: #038FAE;
    --color-blue-light: #82E3FB;
    --color-gray: #D9D9D9;
    --color-gray-dark: #797979;


    --sombra: 0 5px 10px #55a6ff38;
}

@media(min-width: 800px) {
    :root {
        --fonte-tamanho-logo: 2.5rem;
        --fonte-tamanho-menu-cabecalho-item: 1rem;
    }
}
