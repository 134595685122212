.mainHome {
  overflow: hidden;

}

.logoHome {
  width: 250px;
  padding: 20px
}

.logoPixHome {
  width: 200px;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}
  
.backgroundHome {
  bottom: 0;
  position: absolute;
  max-height: calc(100vh - 130px);
  width: 100%;
}

.leftsideHome {
  min-height: 100vh;
}

.rightsideHome {
  background: var(--color-blue);
  min-height: 100vh;
  position: relative;
}

.mainDataHome {
  text-align: center;
}

@media(max-width: 800px) {
  .rightsideHome {
    display: none!important;
  }
  .leftsideHome {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}
