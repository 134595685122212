.mainRegister{
  background-image: url("../../img/textura-register.svg");

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size:cover;  
}


.contentRegister {
  text-align: center;
}